import React from 'react'
import { IoIosGlobe } from 'react-icons/io'
import { FaGithub } from 'react-icons/fa'
import styled from 'styled-components'

function Projects() {
    return (
        <>
            {/* My Projects */}
            <ProjectHeader id="projects-title">My Projects</ProjectHeader>
            <ProjectsMain>
                {/* Project 1 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/15-front.png'
                                }
                                alt="SwedishMarkers"
                            />
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>SwedishMarkers</h2>
                            <p>Find unique locations on a map</p>
                            <ProjectTech>React, react-leaflet</ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://joacimstrandvide.github.io/SwedishMarkers/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/SwedishMarkers"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 2 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/10-front.png'
                                }
                                alt="WhiskyPlus"
                            />
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>WhiskyPlus</h2>
                            <p>Whisky Store</p>
                            <ProjectTech>Vue, Vite</ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://joacimstrandvide.github.io/WhiskyPlus/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/WhiskyPlus"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 3 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/9-front.png'
                                }
                                alt="CountryApi"
                            />
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>CountryAPI</h2>
                            <p>
                                Search information about countries and their
                                national holidays
                            </p>
                            <ProjectTech>HTML, CSS, Javascript</ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="CountryAPI/index.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoIosGlobe /> Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/CountryAPI"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
            </ProjectsMain>
            {/* Collaboration Projects */}
            <ProjectHeader>Collaboration Projects</ProjectHeader>
            <ProjectsMain>
                {/* Project 1 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/16-front.png'
                                }
                                alt="Gomoku Game"
                            />
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Gomoku</h2>
                            <p>Online Gomoku game </p>
                            <ProjectTech>
                                React, styled-components, express
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://github.com/vikluund/Grupp5-Gomoku"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                                <a
                                    href="https://github.com/MelSilgon/Grupp5-Gomoku-backend"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 2 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/13-front.png'
                                }
                                alt="Chat Room"
                            />
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Chat Room</h2>
                            <p>Chat forum</p>
                            <ProjectTech>
                                React, styled-components, axios, express,
                                PostgreSQL
                            </ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://github.com/MartinEmson/FullstackG3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
                {/* Project 3 */}
                <Project>
                    <ProjectContent>
                        <ProjectImage>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/12-front.png'
                                }
                                alt="Resurant"
                            />
                        </ProjectImage>
                        <ProjectDetails>
                            <h2>Resturant</h2>
                            <p>Website for resturant</p>
                            <ProjectTech>Vue, Vuex, Axios</ProjectTech>
                            <ProjectLinks>
                                <a
                                    href="https://github.com/AlexanderAvernas/Grupp5-project"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaGithub /> Source
                                </a>
                            </ProjectLinks>
                        </ProjectDetails>
                    </ProjectContent>
                </Project>
            </ProjectsMain>
        </>
    )
}

export default Projects

const ProjectHeader = styled.h2`
    margin-left: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    margin-top: 6rem;
    color: var(--text);

    @media (max-width: 640px) {
        text-align: center;
        margin-left: 0;
    }
`
const ProjectTech = styled.p`
    font-weight: 500;
`
const ProjectsMain = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    grid-auto-flow: row;
    margin: 5rem 0.8rem 5rem 0.8rem;
    @media (max-width: 640px) {
        grid-template-columns: 1fr;
    }
`
const Project = styled.section`
    color: var(--text);
`
const ProjectContent = styled.section`
    display: flex;

    @media (max-width: 640px) {
        display: block;
    }
`
const ProjectImage = styled.section`
    flex: 1;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
    }
`
const ProjectDetails = styled.section`
    flex: 1;
    padding: 10px;
    font-family: 'Oswald', sans-serif;
    line-height: 1.6;
`
const ProjectLinks = styled.section`
    a {
        margin: 5px 8px 0px 0px;
        padding: 0.5rem;
        color: var(--text);
        font-size: 1.1rem;
        text-decoration: none;
        transition: 0.4s;
        border: 2px solid #000;
        border-radius: 10px;
    }

    a:hover {
        box-shadow: 5px 5px 5px 0px rgba(22, 22, 22, 0.75);
    }
`
