import React from 'react'
import HeaderComp from './components/HeaderComp'
import Projects from './components/Projects'
import Skills from './components/Skills'
import ExperienceTabs from './components/ExperienceTabs'

function App() {
    return (
        <>
            <HeaderComp />
            <Skills />
            <ExperienceTabs />
            <Projects />
        </>
    )
}

export default App
