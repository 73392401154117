import React, { useState } from 'react'
import styled from 'styled-components'

function ExperienceTabs() {
    const [selectedTab, setSelectedTab] = useState('work')

    const workHistory = (
        <>
            {/* Work Experience */}
            <TabsMain>
                <TabsContent>
                    <TabsCard>
                        <h2>Nilo Collaborations</h2>
                        <h5>January 2024 - June 2024</h5>
                        <h4>Front-end Developer Internship</h4>
                        <ul>
                            <li>
                                Collaborated with a team of developers and UX
                                designers to enhance the website’s design and
                                functionality by improving the user experience
                                and resolving bugs.
                            </li>
                            <li>
                                Implemented a new data export feature using
                                React, GraphQL, and Apollo Client, enabling the
                                user to easily export data to a PDF file.
                            </li>
                            <li>
                                Worked in an agile environment using Jira to
                                track sprint goals.
                            </li>
                        </ul>
                    </TabsCard>
                    <TabsCard>
                        <h2>PlotEye</h2>
                        <h5>August 2023 - October 2023</h5>
                        <h4>Front-end Developer Internship</h4>
                        <ul>
                            <li>
                                Developed website functionality independently
                                while working alongside a team of developers and
                                taking tasks from a Trello board in an agile
                                environment.
                            </li>
                            <li>
                                Developed a click-to-zoom feature for users
                                using React and styled-components, which
                                enhanced user-friendliness.
                            </li>
                        </ul>
                    </TabsCard>
                </TabsContent>
            </TabsMain>
        </>
    )

    const educationHistory = (
        <>
            {/* Education */}
            <TabsMain>
                <TabsContent>
                    <TabsCard>
                        <h2>College</h2>
                        <h5>2021 - 2024</h5>
                        <ul>
                            <li>IT-Högskolan, Front-end Developer</li>
                            <li>
                                Högskolan i Borås: Cybersecurity for connected devices (remote)
                            </li>
                            <li>
                                Kristianstad högskola: App development for Android
                                (remote)
                            </li>
                        </ul>
                    </TabsCard>
                </TabsContent>

                <TabsContent>
                    <TabsCard>
                        <h2>High School</h2>
                        <h5>2018 - 2021</h5>
                        <ul>
                            <li>
                                Åva Gymnasium, Information and media technologies
                            </li>
                        </ul>
                    </TabsCard>
                </TabsContent>
            </TabsMain>
        </>
    )

    return (
        <>
            <TabSelect>
                <Tab
                    isActive={selectedTab === 'work'}
                    onClick={() => setSelectedTab('work')}
                >
                    Work
                </Tab>
                <Tab
                    isActive={selectedTab === 'education'}
                    onClick={() => setSelectedTab('education')}
                >
                    Education
                </Tab>
            </TabSelect>

            <div>{selectedTab === 'work' ? workHistory : educationHistory}</div>
        </>
    )
}

export default ExperienceTabs

const TabSelect = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: auto;
`

const Tab = styled.div`
    font-family: 'Oswald', sans-serif;
    font-size: 1.3rem;
    padding: 1rem 1.5rem;
    border-bottom: ${(props) =>
        props.isActive ? '2px solid #4495c9' : 'none'};
    color: ${(props) => (props.isActive ? '#4495c9' : 'black')};
    &:last-child {
        margin-right: 0;
    }
`

const TabsMain = styled.section`
    margin-top: 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media (max-width: 640px) {
        width: 95%;
        margin: auto;
    }
`
const TabsContent = styled.section`
    background-color: var(--background);
    box-shadow: rgba(15, 15, 15, 0.25) 0px 13px 27px -5px,
        rgba(23, 23, 23, 0.3) 0px 8px 16px -8px;
    margin: 1rem auto;
    padding: 2rem;
    position: relative;
    width: 60%;
    border-radius: 20px;
    font-family: 'Oswald', sans-serif;

    @media (max-width: 640px) {
        width: 100%;
        margin: 1rem auto;
    }
`

const TabsCard = styled.section`
    h2 {
        font-weight: 400;
    }

    h5 {
        font-weight: 300;
    }

    h4 {
        font-weight: 400;
    }

    ul li {
        font-weight: 300;
    }
`
