import React from 'react'
import styled from 'styled-components'
import { FaLinkedin, FaGithub } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

function HeaderComp() {
    return (
        <>
            <HeaderMain
                style={{
                    backgroundImage: `url(${
                        process.env.PUBLIC_URL + '/images/background.svg'
                    })`
                }}
            >
                <HeaderText>
                    <HeaderName>Joacim Strandvide</HeaderName>
                    <HeaderTitle>Front-End Developer</HeaderTitle>
                    <HeaderLinks>
                        <a
                            href="https://github.com/joacimstrandvide"
                            target="blank"
                            rel="noopener noreferrer"
                        >
                            <FaGithub /> Github
                        </a>
                        <a href="mailto:joacim@strandvide.se">
                            <MdEmail /> Email
                        </a>
                        <a
                            href="https://www.linkedin.com/in/joacim-strandvide/"
                            target="blank"
                            rel="noopener noreferrer"
                        >
                            <FaLinkedin /> Linkedin
                        </a>
                    </HeaderLinks>
                </HeaderText>
                <HeaderMenu>
                    <a href="#projects-title">View Projects</a>
                    <a href="JoacimSCV.pdf" download>
                        Download Resume
                    </a>
                </HeaderMenu>
            </HeaderMain>
        </>
    )
}

export default HeaderComp

const HeaderMain = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 640px) {
        flex-direction: column;
        text-align: center;
        height: 70vh;
    }
`

const HeaderMenu = styled.section`
    a {
        color: #f0f6fa;
        text-decoration: none;
        font-family: 'Oswald', sans-serif;
        margin: 1rem;
        padding: 0.8rem;
        transition: 0.4s;
        background-color: var(--primary);
        border-radius: 10px;
        border: 2px solid #f0f6fa;
    }

    a:hover {
        box-shadow: 0 0 2vw 0.2vw var(--primary);
    }

    @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            line-height: 1.5rem;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
    }
`

const HeaderText = styled.section`
    text-align: center;
    margin-bottom: 7rem;
`

const HeaderName = styled.h1`
    font-family: 'Oswald', sans-serif;
    line-height: 4vw;
    font-size: 4rem;
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
        0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;

    @media (max-width: 640px) {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }
`

const HeaderTitle = styled.h2`
    font-family: 'Oswald', sans-serif;
    color: #426dfb;
    line-height: 4vw;
    font-size: 3rem;
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
        0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;

    @media (max-width: 640px) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
    }
`
const HeaderLinks = styled.section`
    margin-top: 2rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Oswald', sans-serif;

    a {
        text-decoration: none;
        color: #fff;
        padding: 0.4rem;
        position: relative;
        transition: 0.4s ease;
        font-size: 1.2rem;
        font-weight: 500;
        background-color: var(--primary);
        border-radius: 10px;
        border: 2px solid #f0f6fa;
    }

    a:hover {
        box-shadow: 0 0 1vw 0.2vw var(--primary);
    }
`
