import React from 'react'
import styled from 'styled-components'

function Skills() {
    return (
        <>
            {/* Skills */}
            <SkillsMain>
                <SkillCard>
                    <h2>Frontend Skills</h2>
                    <p>HTML & CSS</p>
                    <p>Javascript</p>
                    <p>VueJS</p>
                    <p>React</p>
                    <p>Typescript</p>
                </SkillCard>

                <SkillCard>
                    <h2>Backend Skills</h2>
                    <p>Node.js</p>
                    <p>SQL</p>
                    <p>GraphQL</p>
                </SkillCard>

                <SkillCard>
                    <h2>Tools</h2>
                    <p>Figma</p>
                    <p>Linux & Bash</p>
                    <p>Git</p>
                    <p>Docker</p>
                    <p>PostgreSQL</p>
                </SkillCard>
            </SkillsMain>
        </>
    )
}

export default Skills

const SkillsMain = styled.section`
    margin: 4rem;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    border-radius: 2rem;
    box-shadow: rgba(15, 15, 15, 0.25) 0px 13px 27px -5px,
        rgba(23, 23, 23, 0.3) 0px 8px 16px -8px;

    @media (max-width: 640px) {
        width: 95%;
        margin: 1rem auto;
        padding: 0rem;
    }
`
const SkillCard = styled.section`
    background: var(--background);
    margin: 2em auto;
    padding: 1em;
    width: 25%;
    font-family: 'Oswald', sans-serif;
    border-radius: 20px;

    h2 {
        font-weight: 400;
        text-align: center;
        color: var(--text);
    }

    p {
        margin-top: 1rem;
        border-radius: 20px;
        text-align: center;
        color: var(--text);
    }

    @media (max-width: 640px) {
        width: 95%;
        margin: 1em auto;
    }
`
